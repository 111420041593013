<template>
  <div class="collaborationworddetail">
    <div class="w_layout">
      <!-- 面包屑 -->
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>研修中心</el-breadcrumb-item>
            <el-breadcrumb-item>教研活动</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">协作文档详情</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="content">
        <div class="left">
          <div class="fsbc">
            <p>5人参与协作文档</p>
            <div>
              <i class="el-icon-download" style="font-size: 20px"></i>
              <span style="margin-left: 10px">下载</span>
              <span>（5）</span>
            </div>
          </div>
          <div class="editor">
            <el-button type="primary">点击在线编辑文档</el-button>
          </div>
        </div>
        <div class="right">
          <div class="fsbc mb20">
            <span>讨论区（30）</span>
            <i class="el-icon-s-unfold" style="font-size: 20px"></i>
          </div>
          <ul>
            <li
              v-for="(item, index) in cgList"
              :key="index"
              class=""
              @click="onSearchCg(item.id)"
            >
              <img :src="item.id == active ? item.icon1 : item.icon2" alt="" />
              <span>{{ item.name }}</span>
            </li>
          </ul>
          <!-- 发布评论 -->
          <div class="my_comment_right">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="写下你的评论..."
              v-model="myContent"
              resize="none"
            ></el-input>
            <div class="release cursor" @click="releaseComment">发表评论</div>
          </div>
          <!-- 评论列表 -->
          <div style="padding: 0 40px">
            <new-comment
              :activity_id="1"
              :is_from="+2"
              :module_type="6"
              ref="commentRef"
            ></new-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myContent: "",
      active: 1,
      cgList: [
        {
          name: "全部",
          id: 1,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
        {
          name: "我的发言",
          id: 2,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
      ],
    };
  },
  methods: {
    // 请求后台发布评论函数
    releaseComment() {
      let p = null;
      var obj = {
        user_id: this.userId,
        content: this.myContent,
      };
      if (this.is_from == 1) {
        obj.course_id = this.id;
        p = this.$axios.post("courseReply/insert_comment", obj);
      } else {
        obj.teaching_studio_course_id = this.id;
        p = this.$axios.post("TeachingStudioCourseReply/insert_comment", obj);
      }
      p.then((res) => {
        this.$message.success("评论成功");
        this.myContent = "";
        // 全部评论
        this.$refs.commentRef.getCommentList();
        // 个人评论评论
        this.$refs.commentRef.getMyCommentInfo();
      });
    },
    // 全部/我的发言
    onSearchCg(id) {
      this.active = id;
    },
  },
};
</script>

<style lang="less" scoped>
.collaborationworddetail {
  padding-bottom: 50px;
  .content {
    display: flex;
    .left {
      width: 70%;
      margin-right: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 20px;
      min-height: 500px;
      .editor {
      }
    }
    .right {
      width: 30%;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 20px 10px 20px 20px;
      ul {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        li {
          margin-right: 10%;
          > img {
            vertical-align: text-bottom;
            margin-right: 16px;
          }
        }
      }
      .my_comment_right {
        height: 150px;
        border: 1px solid #ececec;
        border-radius: 4px;
        position: relative;
        .release {
          width: 92px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background: #fa8c15;
          border-radius: 4px;
          font-weight: 700;
          color: #ffffff;
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
        /deep/ .el-textarea__inner {
          border: none;
        }
      }
    }
  }
}
</style>